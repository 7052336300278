﻿@import (reference) "lib/bootstrap-references.less";

.purchase-product-modal {

    .product-narrative {
        float: right;
    }

    .modal-footer {
        
        div.product-impression {
            display: inline;
        }

        .btn {
            width: unset;
            padding: 6px 12px;
        }
    }
}
