.purchase-product-modal .product-narrative {
  float: right;
}
.purchase-product-modal .modal-footer div.product-impression {
  display: inline;
}
.purchase-product-modal .modal-footer .btn {
  width: unset;
  padding: 6px 12px;
}
